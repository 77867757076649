import React, { Fragment, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useFormattedMessage } from "hooks";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import pick from "lodash/pick";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import NextButton from "components/NextButton";
import PrevButton from "components/PrevButton";
import { setMySettings } from "api";
import { setInvestmentStepData } from "store/user/investment/actions";
import { selectCurrenKYCStatus, selectKYCStatusShow } from "store/user/kyc/selectors";

import {
    email,
    firstName,
    lastName,
    gender,
    titel,
    street,
    streetNumber,
    zipcode,
    city,
    country,
    streetAddition,
    dataActions,
} from "i18n/messages/formElements";
import { sendFormError } from "i18n/messages/errors";

import { validationObject, checkValueInArray } from "utils/yupValidation";
import { getTitles, getGenders } from "utils";
import SideModal from "components/SideModal";
import CountryPicker from "components/CountryPicker";
import { setKYCStart, updateKYCFormData } from "store/user/kyc/actions";
import { KYC_STATUS_LOCAL } from "constants/status";
import { PERSONAL_FORM_INPUTS } from "constants";

const cookiesPkg = require("js-cookie");
const FINX_COOKIE = require("common/constants");

const messages = {
    ...email,
    ...firstName,
    ...lastName,
    ...gender,
    ...titel,
    ...street,
    ...streetNumber,
    ...zipcode,
    ...city,
    ...country,
    ...streetAddition,
    ...dataActions,
    sendFormError,
    pleaseSelect: "please_select",
    submitButtonText: "tipster_form_button_text",
    heading: "personal_data_confirmation",
    pageHeading: "please_confrim_change_of_data",
    question: "changes_aaply_to_registration",
    paraOne: "change_registration_address_identify_yourself",
    paraTwo: "identify_yourself_again",
    yes: "yes_change_registration_address",
    no: "no_text",
    cancel: "form_cancel",
    specialCharactersNotAllowed: "please_enter_valid_character",
    identificationData: "meta_title_personal_data_page",
};

const ChangeAddressConfirmationModal = ({
    formatMessage = () => {},
    onConfirm = () => {},
    onCancelClick = () => {},
    isSubmittingYes,
    isSubmittingNo,
    errorMessage,
}) => {
    return (
        <div className="flex column py-3">
            <p className="text-16 fw-600 mb-3">{formatMessage(messages.pageHeading)}</p>
            <p className="text-16">{formatMessage(messages.question)}</p>
            <p className="text-16">{formatMessage(messages.paraOne)}</p>
            <p className="text-16">{formatMessage(messages.paraTwo)}</p>
            {errorMessage && (
                <label className="error-label text-left alert-red-color">{formatMessage(messages[errorMessage])}</label>
            )}
            <div className="mt-4">
                <NextButton
                    onClick={() => onConfirm("yes")}
                    isLoading={isSubmittingYes}
                    className="w-100 mw-100 mb-3"
                    title={formatMessage(messages.yes)}
                />
                <NextButton
                    onClick={() => onConfirm("no")}
                    isLoading={isSubmittingNo}
                    className="w-100 mw-100 mb-3"
                    title={formatMessage(messages.no)}
                />
                <NextButton onClick={onCancelClick} className="secondary w-100 mw-100" title={formatMessage(messages.cancel)} />
            </div>
        </div>
    );
};
const ProfileForm = ({
    kycShow,
    userData,
    setStep,
    open,
    userAdditionalData,
    setInvestmentStepData,
    backSlideEffect = "",
    setBackSlideEffect,
    onCloseButtonClick,
    setKYCStartAPI,
    currentKYCStatus,
    updateKycForm,
}) => {
    const inheritUserToken = cookiesPkg.get(FINX_COOKIE.INHERIT_USER_TOKEN);
    const inheritUserEmail = cookiesPkg.get(FINX_COOKIE.INHERIT_USER_EMAIL);
    const isInheritUserEnabled = !!(inheritUserToken && inheritUserEmail);

    const [isShowConfirmationModal, setShowConfirmationModal] = useState(false);
    const { formatMessage } = useFormattedMessage();
    const titles = getTitles(formatMessage);
    const genders = getGenders(formatMessage);

    const onFormSubmit = () => {
        if (
            currentKYCStatus === KYC_STATUS_LOCAL.COMPLETED &&
            (userData?.LastName !== values?.LastName || userData?.City !== values?.City || userData?.Zipcode !== values?.Zipcode)
        ) {
            setShowConfirmationModal(true);
        } else {
            submitForm();
        }
    };

    const onConfirm = (action = "no") => {
        setFieldValue("enableReKYC", action === "yes" ? true : false);
        submitForm();
    };

    function hasValidAddressField(obj) {
        return Object.values(obj).some((value) => {
            if (typeof value === "object" && value !== null) {
                return Object.keys(value).length > 0;
            }
            return value !== "";
        });
    }

    const {
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        setFieldValue,
        setFieldTouched,
        resetForm,
    } = useFormik({
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: {
            FirstName: userData.FirstName || "",
            LastName: userData.LastName || "",
            TypeTitle: userData.TypeTitle || null,
            TypeGender: userData.TypeGender || null,
            Email: userData.Email || "",
            StreetAddition: userData.StreetAddition || "",
            City: userData.City || "",
            Zipcode: userData.Zipcode || "",
            Street: userData.Street || "",
            Country: userData.Country || {},
            Streetnumber: userData.Streetnumber || "",
            enableReKYC: false,
        },
        validationSchema: yup.object().shape({
            FirstName: validationObject.firstName,
            LastName: validationObject.lastName,
            TypeGender:
                kycShow &&
                checkValueInArray("gender", "genderInvalid", ["", "male", "female", "diverse"], true, "genderRequired"),
            Street: kycShow && validationObject.street,
            Streetnumber: kycShow && validationObject.streetNumber,
            City: kycShow && validationObject.city,
            Zipcode: kycShow && validationObject.zipcode,
        }),
        onSubmit: (values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            const personalData = pick(values, [
                "FirstName",
                "LastName",
                "TypeTitle",
                "TypeGender",
                "Street",
                "Streetnumber",
                "StreetAddition",
                "StreetAddition",
                "Zipcode",
                "City",
                "Country",
                "Owner",
                "IBAN",
                "BIC",
                "Number",
                "DialCode",
                "CountryCode",
            ]);
            const setMySettingsData = {};
            const address = pick(values, ["Street", "Streetnumber", "StreetAddition", "Zipcode", "City", "Country"]);
            setMySettingsData.address = { ...address, Country: (values.Country && values.Country.value) || "" };
            setMySettingsData.firstName = values.FirstName;
            setMySettingsData.lastName = values.LastName;
            setMySettingsData.gender = (values.TypeGender && values.TypeGender.value) || "";
            setMySettingsData.title = (values.TypeTitle && values.TypeTitle.value) || "";
            const enableReKYC = values.enableReKYC;

            setMySettings(setMySettingsData)
                .then((res) => {
                    setSubmitting(false);
                    if (res.data && res.data.errors && res.data.errors[0]) {
                        setErrors({ requestError: "send_form_error" });
                    } else {
                        setInvestmentStepData({
                            userData: {
                                personalData: {
                                    ...userAdditionalData.personalData,
                                    ...personalData,
                                    TypeGender: personalData.TypeGender && personalData.TypeGender.value,
                                },
                            },
                        });
                        //update details in the KYC
                        const kycFields = pick(values, PERSONAL_FORM_INPUTS);
                        updateKycForm?.({
                            ...userAdditionalData.personal,
                            personal: kycFields,
                        });
                        if (hasValidAddressField(address) && !isInheritUserEnabled) {
                            setKYCStartAPI({
                                user: { ...userData, ...personalData },
                                fromProfile: true,
                                enableReKYC,
                                currentKYCStatus: currentKYCStatus,
                            });
                        }
                        setStep(1);
                    }
                })
                .catch((error) => {
                    console.error("error set my settings api errors", error);
                    setSubmitting(false);
                });
        },
    });

    return (
        <>
            <SideModal
                className="profile-confirmation-modal"
                heading={formatMessage(messages.identificationData)}
                open={isShowConfirmationModal}
                onCloseButtonClick={() => {
                    resetForm();
                    setShowConfirmationModal(false);
                }}
            >
                <ChangeAddressConfirmationModal
                    formatMessage={formatMessage}
                    onConfirm={onConfirm}
                    onCancelClick={() => {
                        resetForm();
                        setShowConfirmationModal(false);
                    }}
                    errorMessage={errors.requestError}
                    isSubmittingYes={values.enableReKYC && isSubmitting}
                    isSubmittingNo={!values.enableReKYC && isSubmitting}
                    onCloseButtonClick={onCloseButtonClick}
                />
            </SideModal>
            <form method="post" onSubmit={handleSubmit} className={backSlideEffect}>
                <div className="data-block">
                    <div className="details">
                        <div className="flex column">
                            <Input
                                type="text"
                                name="FirstName"
                                id="FirstName"
                                label={formatMessage(messages.firstNamePlaceholder)}
                                placeholder={formatMessage(messages.firstNamePlaceholder)}
                                onChange={handleChange}
                                value={values.FirstName}
                                onBlur={handleBlur}
                                errorMessage={errors.FirstName ? formatMessage(messages[errors.FirstName]) : null}
                                error={touched.FirstName && errors.FirstName}
                            />
                            <Input
                                type="text"
                                name="LastName"
                                id="LastName"
                                label={formatMessage(messages.lastNamePlaceholder)}
                                placeholder={formatMessage(messages.lastNamePlaceholder)}
                                onChange={handleChange}
                                value={values.LastName}
                                onBlur={handleBlur}
                                errorMessage={errors.LastName ? formatMessage(messages[errors.LastName]) : null}
                                error={touched.LastName && errors.LastName}
                            />
                            <Input
                                readOnly
                                type="text"
                                name="Email"
                                id="Email"
                                label={formatMessage(messages.emailPlaceholder)}
                                placeholder={formatMessage(messages.emailPlaceholder)}
                                inputClassName="bg-lighter-grey"
                                onChange={handleChange}
                                value={values.Email}
                                onBlur={handleBlur}
                                errorMessage={errors.Email ? formatMessage(messages[errors.Email]) : null}
                                error={touched.Email && errors.Email}
                            />
                            {!kycShow && (
                                <>
                                    <SelectInput
                                        maxMenuHeight={400}
                                        isSearchable={false}
                                        classNamePrefix="select"
                                        name="TypeTitle"
                                        options={titles}
                                        label={formatMessage(messages.titelPlaceholder)}
                                        placeholder={formatMessage(messages.pleaseSelect)}
                                        value={values.TypeTitle}
                                        disabled={!!userData.TypeTitle}
                                        selectClassName={!!userData.TypeTitle ? "disable-pointer disable-input-bg" : ""}
                                        onChange={(value) => {
                                            setFieldTouched("TypeTitle");
                                            setFieldValue("TypeTitle", value);
                                        }}
                                    />
                                    <SelectInput
                                        maxMenuHeight={400}
                                        isSearchable={false}
                                        classNamePrefix="select"
                                        name="TypeGender"
                                        options={genders}
                                        placeholder={formatMessage(messages.pleaseSelect)}
                                        label={formatMessage(messages.genderPlaceholder)}
                                        value={values.TypeGender}
                                        disabled={!!userData.TypeGender}
                                        selectClassName={!!userData.TypeGender ? "disable-pointer disable-input-bg" : ""}
                                        onChange={(value) => {
                                            setFieldTouched("TypeGender");
                                            setFieldValue("TypeGender", value);
                                        }}
                                        errorMessage={errors.TypeGender ? formatMessage(messages[errors.TypeGender]) : null}
                                        error={touched.TypeGender && errors.TypeGender}
                                    />
                                </>
                            )}
                            {kycShow && (
                                <Fragment>
                                    <SelectInput
                                        maxMenuHeight={400}
                                        isSearchable={false}
                                        classNamePrefix="select"
                                        name="TypeTitle"
                                        options={titles}
                                        label={formatMessage(messages.titelPlaceholder)}
                                        placeholder={formatMessage(messages.pleaseSelect)}
                                        value={values.TypeTitle}
                                        onChange={(value) => {
                                            setFieldTouched("TypeTitle");
                                            setFieldValue("TypeTitle", value);
                                        }}
                                    />
                                    <SelectInput
                                        maxMenuHeight={400}
                                        isSearchable={false}
                                        classNamePrefix="select"
                                        name="TypeGender"
                                        options={genders}
                                        placeholder={formatMessage(messages.pleaseSelect)}
                                        label={formatMessage(messages.genderPlaceholder)}
                                        value={values.TypeGender}
                                        onChange={(value) => {
                                            setFieldTouched("TypeGender");
                                            setFieldValue("TypeGender", value);
                                        }}
                                        errorMessage={errors.TypeGender ? formatMessage(messages[errors.TypeGender]) : null}
                                        error={touched.TypeGender && errors.TypeGender}
                                    />
                                    <Input
                                        type="text"
                                        name="Street"
                                        id="Street"
                                        label={formatMessage(messages.streetPlaceholder)}
                                        placeholder={formatMessage(messages.streetPlaceholder)}
                                        onChange={handleChange}
                                        value={values.Street}
                                        onBlur={handleBlur}
                                        errorMessage={errors.Street ? formatMessage(messages[errors.Street]) : null}
                                        error={touched.Street && errors.Street}
                                    />
                                    <Input
                                        type="text"
                                        name="Streetnumber"
                                        id="Streetnumber"
                                        label={formatMessage(messages.streetNumberPlaceholder)}
                                        placeholder={formatMessage(messages.streetNumberPlaceholder)}
                                        onChange={handleChange}
                                        value={values.Streetnumber}
                                        onBlur={handleBlur}
                                        errorMessage={errors.Streetnumber ? formatMessage(messages[errors.Streetnumber]) : null}
                                        error={touched.Streetnumber && errors.Streetnumber}
                                    />
                                    <Input
                                        type="text"
                                        name="StreetAddition"
                                        id="StreetAddition"
                                        label={formatMessage(messages.streetAdditionPlaceholder)}
                                        placeholder={formatMessage(messages.streetAdditionPlaceholder)}
                                        onChange={handleChange}
                                        value={values.StreetAddition}
                                        onBlur={handleBlur}
                                    />
                                    <Input
                                        type="text"
                                        name="Zipcode"
                                        id="Zipcode"
                                        label={formatMessage(messages.zipcodePlaceholder)}
                                        placeholder={formatMessage(messages.zipcodePlaceholder)}
                                        onChange={handleChange}
                                        value={values.Zipcode}
                                        onBlur={handleBlur}
                                        errorMessage={errors.Zipcode ? formatMessage(messages[errors.Zipcode]) : null}
                                        error={touched.Zipcode && errors.Zipcode}
                                    />
                                    <Input
                                        type="text"
                                        name="City"
                                        id="City"
                                        label={formatMessage(messages.cityPlaceholder)}
                                        placeholder={formatMessage(messages.cityPlaceholder)}
                                        onChange={handleChange}
                                        value={values.City}
                                        onBlur={handleBlur}
                                        errorMessage={errors.City ? formatMessage(messages[errors.City]) : null}
                                        error={touched.City && errors.City}
                                    />
                                    <CountryPicker
                                        name="Country"
                                        label={formatMessage(messages.countryPlaceholder)}
                                        placeholder={formatMessage(messages.pleaseSelect)}
                                        value={values.Country}
                                        onCountryChange={(value) => {
                                            setFieldTouched("Country");
                                            setFieldValue("Country", value);
                                        }}
                                    />
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
                {open && (
                    <div className="bottom-bar-container">
                        <PrevButton
                            className="pd-back-button"
                            onClick={() => {
                                setStep(1);
                                setBackSlideEffect("step-slide-right");
                            }}
                        />
                        <NextButton
                            disable={Object.keys(errors).length}
                            // type="submit"
                            onClick={onFormSubmit}
                            isLoading={isSubmitting}
                            title={formatMessage(messages.submitButtonText)}
                        />
                    </div>
                )}
            </form>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    kycShow: selectKYCStatusShow,
    currentKYCStatus: selectCurrenKYCStatus,
});

const mapDispatchToProps = (dispatch) => ({
    setInvestmentStepData: (data) => dispatch(setInvestmentStepData(data)),
    setKYCStartAPI: (kycData) => dispatch(setKYCStart(kycData)),
    updateKycForm: (data) => dispatch(updateKYCFormData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
