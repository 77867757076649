module.exports = {
    prod: {
        REACT_APP_FINEXITY_ENVIRONMENT: "prod",

        AUTH0_CLIENT_ID: "7Ex7His2hSIrp2NI8khgEeaD0uS79MZt",
        AUTH0_APP_CLIENT_ID: "YqV5zgPo3hVrYy6WH9kPJ3LVqvno2M1c",
        AUTH0_ENVIRONMENT: "Prod",

        APP_ENVIRONMENT: "production",
        RELEASE_VERSION: "finexity-production",
        AUDIENCE: "https://api.finexity.com",

        NODE_ENV: "production",
        IS_LOGGER_ENABLED: false,
    },
    test: {
        REACT_APP_FINEXITY_ENVIRONMENT: "test",

        AUTH0_CLIENT_ID: "xzKbqX6w4qQX4I6xsNloXpHqCGq3FH08",
        AUTH0_APP_CLIENT_ID: "7V6MJaY74NLvWjp7rViOSrekp0xKcsD5",
        AUTH0_ENVIRONMENT: "Test",

        APP_ENVIRONMENT: "testing",
        RELEASE_VERSION: "finexity-testing",
        AUDIENCE: "https://api.test.finexity.com",

        NODE_ENV: "production",
        IS_LOGGER_ENABLED: true,
    },
    dev: {
        REACT_APP_FINEXITY_ENVIRONMENT: "dev",

        AUTH0_CLIENT_ID: "HrlPY0dVWeYVdB7MB3fsZBMnoIJN1GN8",
        AUTH0_APP_CLIENT_ID: "728RWa5afAtMRL1l1F1B44t5VGp3fKsm",
        AUTH0_ENVIRONMENT: "Dev",

        APP_ENVIRONMENT: "development",
        RELEASE_VERSION: "finexity-development",
        AUDIENCE: "https://api.dev.finexity.com",

        NODE_ENV: "production",
        IS_LOGGER_ENABLED: true,
    },
    staging: {
        REACT_APP_FINEXITY_ENVIRONMENT: "staging",

        AUTH0_CLIENT_ID: "7Ex7His2hSIrp2NI8khgEeaD0uS79MZt",
        AUTH0_APP_CLIENT_ID: "YCsXkvn7WsrqlnjnnYDoHsU2Igcb8uw2",
        AUTH0_ENVIRONMENT: "Prod",

        APP_ENVIRONMENT: "staging",
        RELEASE_VERSION: "finexity-staging",
        AUDIENCE: "https://api.staging.finexity.com",

        NODE_ENV: "production",
        IS_LOGGER_ENABLED: false,
    },
    "localhost:3000": {
        REACT_APP_FINEXITY_ENVIRONMENT: "local",

        AUTH0_CLIENT_ID: "xzKbqX6w4qQX4I6xsNloXpHqCGq3FH08",
        AUTH0_APP_CLIENT_ID: "7V6MJaY74NLvWjp7rViOSrekp0xKcsD5",
        AUTH0_ENVIRONMENT: "Test",

        APP_ENVIRONMENT: "local-development",
        RELEASE_VERSION: "finexity-testing",
        AUDIENCE: "https://api.test.finexity.com",

        NODE_ENV: "development",
        IS_LOGGER_ENABLED: true,
    },
    default: {
        REACT_APP_FINEXITY_ENVIRONMENT: "dev",

        AUTH0_CLIENT_ID: "HrlPY0dVWeYVdB7MB3fsZBMnoIJN1GN8",
        AUTH0_APP_CLIENT_ID: "728RWa5afAtMRL1l1F1B44t5VGp3fKsm",
        AUTH0_ENVIRONMENT: "Dev",

        APP_ENVIRONMENT: "development",
        RELEASE_VERSION: "finexity-development",
        AUDIENCE: "https://api.dev.finexity.com",

        NODE_ENV: "production",
        IS_LOGGER_ENABLED: true,
    },
    common: {
        AUTH0_DOMAIN: "login.finexity.com",
        WEBSITE_IOS_PAGE: "/app/iOS",
        WEBSITE_ANDROID_PAGE: "/app/Android",
    },
};
