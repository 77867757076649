function checkIsLocalEnv(currentHost) {
    return currentHost?.includes("localhost") || process.env.NODE_ENV === "development" || currentHost?.includes("ngrok.io");
}

module.exports.loadCurrentHostConfig = (currentWindow) => {
    const FINX_ALL_CONFIG = require("common/config");
    currentWindow = typeof window !== "undefined" && !currentWindow ? window : currentWindow;
    return FINX_ALL_CONFIG[this.getEnvFromHost(currentWindow?.location?.host)] || FINX_ALL_CONFIG["default"];
};

module.exports.getTenantFromHost = (currentHost) => {
    const finexityTenant = "finexity";
    const finexityTenantHostName = "www.finexity.com";
    const finexityTenantHost = "finexity.com";

    if (!currentHost) return finexityTenant;

    if (currentHost?.includes("finexity-healthcare") || currentHost?.includes("volksimmoinvest")) {
        return "finexity-healthcare";
    }

    if (
        checkIsLocalEnv(currentHost) ||
        currentHost === finexityTenantHostName ||
        currentHost === finexityTenantHost ||
        currentHost?.includes("pentest")
    ) {
        return finexityTenant;
    }

    const parseHostName = currentHost?.split("://");
    const hostArray =
        Array.isArray(parseHostName) && parseHostName.length > 1 ? parseHostName[1].split(".") : parseHostName[0].split(".");
    const finexityTenantIndex = hostArray.findIndex((tenant) => tenant === finexityTenant);

    const currentIndex =
        hostArray[0] === "dev" || hostArray[0] === "test" || hostArray[0] === "staging" || hostArray[0] === "beta" ? 1 : 0;
    if (finexityTenantIndex === 1) {
        if (hostArray[0] && hostArray[0].includes("feature")) return hostArray[1];
        return hostArray[currentIndex];
    } else if (finexityTenantIndex === -1) {
        if (hostArray.length > 2) return hostArray[1];
        return hostArray[0];
    } else if (finexityTenantIndex === 2) return hostArray[currentIndex];
    else if (hostArray[0] === finexityTenant) return finexityTenant;
    else return finexityTenant;
};

module.exports.getEnvFromHost = (currentHost) => {
    let env = "dev";

    if (currentHost?.startsWith("localhost")) env = "localhost:3000";
    else if (currentHost?.includes("dev.") || currentHost?.includes("feature")) env = "dev";
    else if (currentHost?.includes("test.")) env = "test";
    else if (currentHost?.includes("staging.")) env = "staging";
    else env = "prod";

    return env;
};

module.exports.getDocumentsHost = (currentWindow) => {
    currentWindow = typeof window !== "undefined" ? window : currentWindow;

    const currentHost = currentWindow?.location?.host;
    if (checkIsLocalEnv(currentHost)) return "https://test.finexity.com";

    return `https://${currentHost}`;
};

module.exports.getBaseApiURL = (currentWindow) => {
    currentWindow = typeof window !== "undefined" ? window : currentWindow;

    const currentHost = currentWindow?.location?.host;
    const targetHostName = checkIsLocalEnv(currentHost) ? "test.finexity.com" : currentHost;

    return `https://${targetHostName}`;
};

module.exports.getAppleWalletURL = (assetID) => {
    const apiGatewayURL = this.getApiGatewayUrl();
    return `${apiGatewayURL}/wallet/v1/create-pkpass/${assetID}`;
};

module.exports.getHostName = (currentWindow) => {
    currentWindow = typeof window !== "undefined" ? window : currentWindow;

    const currentHost = currentWindow?.location?.host;
    if (checkIsLocalEnv(currentHost)) return "test.finexity.com";

    return currentHost;
};

module.exports.getHost = (currentWindow) => {
    currentWindow = typeof window !== "undefined" ? window : currentWindow;
    const finxConfig = this.loadCurrentHostConfig();

    if (process.env.NODE_ENV === "development" || finxConfig?.REACT_APP_FINEXITY_ENVIRONMENT === "local") {
        return typeof currentWindow === "undefined"
            ? ""
            : `${currentWindow?.location?.protocol}//${currentWindow?.location?.host}`;
    }

    return `https://${currentWindow?.location?.host}`;
};

module.exports.getApiGatewayUrl = () => {
    const hostUrl = {
        dev: "https://api.dev.finexity.com",
        test: "https://api.test.finexity.com",
        prod: "https://api.finexity.com",
    };

    if (typeof window === "undefined") return hostUrl.test;

    const env = this.getEnvFromHost(window?.location?.host);
    return hostUrl[env] || hostUrl.test;
};

module.exports.checkValidString = (string) => {
    return !!(string && string !== "undefined" && string !== "null");
};
