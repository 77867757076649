import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setInvestmentStepData } from "store/user/investment/actions";
import { selectUserDetails } from "store/user/account/selectors";

const pick = require("lodash/pick");
import { ADDRESS_TYPES, BANK_ACCOUNT_TYPES, PHONE_TYPES, USER_KYC_DETAILS_INPUT } from "constants/index";
import SideModal from "components/SideModal";
import PersonalData from "./PersonalData";
import IdentificationData from "./IdentificationData";
import KycProvider from "./KycProvider";
import PostidentPdfInstructions from "./PostidentPdfInstructions";
import KycInfo from "./KycInfo";
import { useFormattedMessage } from "hooks";
import { fetchKYCStart, setKYCFailure, setKYCStart, updateKYCFormData } from "store/user/kyc/actions";
import {
    selectCurrenKYCStatus,
    selectHasUserInitiated,
    selectKYCError,
    selectKYCSubmitting,
    selectKycFormData,
} from "store/user/kyc/selectors";
import { KYCTileAPI } from "api";

const cookiesPkg = require("js-cookie");
const FINX_COOKIE = require("common/constants");

const messages = {
    kycMainHeading: "your_identification",
};

export const RenderKycStep = ({
    step,
    userAdditionalData,
    setStep,
    setInvestmentStepData,
    setUserDetails,
    onCloseButtonClick,
    isWithSecondaryMarket,
    onPersonalBack,
    updateKycForm,
    open,
    isKYCTileAPILoading,
    isKYCInitiatedSuccess,
    kycError,
    isInheritUserEnabled,
}) => {
    const [backSlideEffect, setBackSlideEffect] = useState("");

    useEffect(() => {
        isKYCInitiatedSuccess && setBackSlideEffect("step-slide-left");
    }, [isKYCInitiatedSuccess]);

    function renderStep(step) {
        const userPersonalData = (userAdditionalData && userAdditionalData.personal) || {};

        if (
            !(
                userPersonalData.TypeGender === "female" ||
                userPersonalData.TypeGender === "male" ||
                userPersonalData.TypeGender === "diverse"
            )
        ) {
            userPersonalData.TypeGender = "";
        }
        const identificationData =
            (userAdditionalData &&
                userAdditionalData.identification &&
                Object.keys(userAdditionalData.identification).length &&
                userAdditionalData.identification) ||
            {};

        switch (step) {
            case 1:
                return (
                    <PersonalData
                        open={open}
                        isWithSecondaryMarket={isWithSecondaryMarket}
                        isRequiredBackButton={isWithSecondaryMarket}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (isWithSecondaryMarket) {
                                onPersonalBack();
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        data={userPersonalData}
                        onSubmit={(values) => {
                            setStep && setStep(2);
                            !isInheritUserEnabled &&
                                updateKycForm?.({
                                    ...userPersonalData,
                                    personal: values,
                                });
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                );
            case 2:
                return (
                    <IdentificationData
                        isSubmitButtonLoading={isKYCTileAPILoading}
                        open={open}
                        onCloseButtonClick={onCloseButtonClick}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (setStep) {
                                setStep(1);
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        data={identificationData}
                        onSubmit={(values) => {
                            if (!isInheritUserEnabled) {
                                updateKycForm?.({
                                    ...identificationData,
                                    identification: values,
                                });
                                const userDetails = { ...userAdditionalData, identification: values };
                                setUserDetails(userDetails);
                            }
                        }}
                        kycError={kycError}
                        isInheritUserEnabled={isInheritUserEnabled}
                    />
                );
            case 3:
                return (
                    <KycProvider
                        open={open}
                        isRequiredBackButton
                        externalUrl={userAdditionalData?.IDnowRedirectURL}
                        onExternalLinkClick={() => {
                            setStep && setStep(5);
                            setBackSlideEffect("step-slide-left");
                        }}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (setStep) {
                                setStep(2);
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        onPostidentClick={() => {
                            setStep && setStep(4);
                            setBackSlideEffect("step-slide-left");
                        }}
                        isInheritUserEnabled={isInheritUserEnabled}
                    />
                );
            case 4:
                return (
                    <PostidentPdfInstructions
                        open={open}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (setStep) {
                                setStep(3);
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        onDownloadClick={() => {
                            setStep && setStep(5);
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                );
            case 5:
                return (
                    <KycInfo
                        open={open}
                        onCloseButtonClick={onCloseButtonClick}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (setStep) {
                                setStep(3);
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                    />
                );
            default:
                return (
                    <PersonalData
                        open={open}
                        isRequiredBackButton={isWithSecondaryMarket}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (isWithSecondaryMarket) {
                                onPersonalBack();
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        data={userPersonalData}
                        onSubmit={(values) => {
                            setStep && setStep(2);
                            setInvestmentStepData && setInvestmentStepData({ userData: { personalData: values } });
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                );
        }
    }
    return renderStep(step);
};

const KycModal = ({
    open,
    onCloseButtonClick,
    onBackButtonClick,
    isCloseArrowOnRight,
    userAdditionalData,
    setKycAtStep1,
    setInvestmentStepData,
    setKYCStartAPI,
    activeStep,
    setActiveStep,
    fetchKYCStart,
    updateKycForm,
    currentKYCStatus,
    isKYCSubmitting,
    hasUserInitiatedKYC,
    kycError,
    setKYCFailure,
}) => {
    const inheritUserToken = cookiesPkg.get(FINX_COOKIE.INHERIT_USER_TOKEN);
    const inheritUserEmail = cookiesPkg.get(FINX_COOKIE.INHERIT_USER_EMAIL);
    const isInheritUserEnabled = !!(inheritUserToken && inheritUserEmail);

    const [step, setStep] = useState(1);
    const [isKYCTileAPILoading, setKYCTileAPILoading] = useState(false);

    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        // If KYC initiated, switch to provider step
        if (!isKYCSubmitting && hasUserInitiatedKYC && !kycError) {
            setStep(3);
        }
    }, [isKYCSubmitting, hasUserInitiatedKYC, open]);

    const setUserDetailsAPI = (kycData) => {
        setKYCTileAPILoading(true);

        let kycDetails = { ...kycData.personal, ...kycData.identification };
        kycDetails = pick(kycDetails, USER_KYC_DETAILS_INPUT);

        const {
            TypeTitle,
            Country,
            BirthCountry,
            Nationality,
            MaritalStatus,
            IsEffectaCustomerInformationConfirmed,
            ...restWithoutTypeTitle
        } = kycDetails;

        const KYCTileAPIPayload = {
            ...restWithoutTypeTitle,
            TypeTitle: TypeTitle?.value,
            TypePhone: PHONE_TYPES.TypePhoneMobile,
            TypeBankAccount: BANK_ACCOUNT_TYPES.TypeBankAccountUserPrimary,
            TypeAddress: ADDRESS_TYPES.TypeAddressPrimary,
            Country: Country?.value,
            BirthCountry: BirthCountry?.value,
            Nationality: Nationality?.value,
            MaritalStatus: MaritalStatus?.value,
            IsEffectaCustomerInformationConfirmed: IsEffectaCustomerInformationConfirmed === true ? "true" : "false",
        };

        KYCTileAPI(KYCTileAPIPayload)
            .then((res) => {
                setKYCTileAPILoading(false);

                if (res?.data?.data?.kycTile?.user?.Email) {
                    setKYCStartAPI({
                        user: kycDetails,
                        currentKYCStatus,
                    });
                }
            })
            .catch(() => {
                setKYCTileAPILoading(false);
            });
    };

    useEffect(() => {
        if (activeStep) {
            step !== 1 ? (step === 5 ? setStep(3) : setStep(step - 1)) : setKycAtStep1();
            setActiveStep();
        }
    }, [activeStep]);

    useEffect(() => {
        open && !isInheritUserEnabled && fetchKYCStart();
    }, [open]);

    useEffect(() => {
        !isInheritUserEnabled && setKYCFailure("");
    }, [step]);

    const memoizedRenderKycStep = useMemo(() => {
        return (
            <RenderKycStep
                open={open}
                setStep={(step) => (step === null ? onCloseButtonClick() : setStep(step))}
                step={step}
                updateKycForm={updateKycForm}
                userAdditionalData={userAdditionalData}
                setInvestmentStepData={setInvestmentStepData}
                setUserDetails={setUserDetailsAPI}
                onCloseButtonClick={onCloseButtonClick}
                isKYCTileAPILoading={isKYCTileAPILoading}
                isKYCInitiatedSuccess={!isKYCSubmitting && hasUserInitiatedKYC}
                kycError={kycError}
                isInheritUserEnabled={isInheritUserEnabled}
            />
        );
    }, [step, userAdditionalData, isKYCSubmitting, hasUserInitiatedKYC, isKYCTileAPILoading, open]);

    return (
        <SideModal
            className="kyc-modal"
            heading={formatMessage(messages.kycMainHeading)}
            open={open}
            isBackArrowOnTop={step === 1}
            isCloseArrowOnRight={isCloseArrowOnRight}
            onBackButtonClick={onBackButtonClick}
            onCloseButtonClick={onCloseButtonClick}
        >
            {memoizedRenderKycStep}
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    userAdditionalData: selectKycFormData,
    userData: selectUserDetails,
    currentKYCStatus: selectCurrenKYCStatus,
    isKYCSubmitting: selectKYCSubmitting,
    hasUserInitiatedKYC: selectHasUserInitiated,
    kycError: selectKYCError,
});

const mapDispatchToProps = (dispatch) => ({
    setInvestmentStepData: (data) => dispatch(setInvestmentStepData(data)),
    setKYCStartAPI: (data) => dispatch(setKYCStart(data)),
    fetchKYCStart: () => dispatch(fetchKYCStart()),
    updateKycForm: (data) => dispatch(updateKYCFormData(data)),
    setKYCFailure: (data) => dispatch(setKYCFailure(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KycModal);
