import { getHost } from "common/helper";

const FINX_CURRENT_CONFIG = require("common/config");
const HOST = getHost();

const commonConfig = FINX_CURRENT_CONFIG["common"];
const currentConfigByHost = require("common/helper").loadCurrentHostConfig();

export default {
    domain: commonConfig.AUTH0_DOMAIN,
    clientId: currentConfigByHost.AUTH0_CLIENT_ID,
    appClientId: currentConfigByHost.AUTH0_APP_CLIENT_ID,
    environment: currentConfigByHost.AUTH0_ENVIRONMENT,
    audience: currentConfigByHost.AUDIENCE,
    callbackUrl: `${HOST}/token`,
    scope: "read:status openid profile email user_metadata roles user_id name",
    custom_app_scope: "read:status openid profile email user_metadata roles user_id name offline_access",
};
