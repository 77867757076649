import React, { useEffect, useState } from "react";
import { createMarkup } from "utils";

let showSheet = false;

function ToolTipBottomSheet() {
    const [description, setDescription] = useState("");

    const onClickQuestion = (e) => {
        if (!showSheet) {
            showSheet = true;
            const datatip = e.target.getAttribute("data-tooltip-content") || e.target.getAttribute("data-tooltip-html");
            setDescription(datatip);
        } else {
            // eslint-disable-next-line no-use-before-define
            onHide();
        }
        e.preventDefault();
    };
    function onHide() {
        showSheet = false;
        $("#tool-tip-overlay").fadeOut(200);
        $("#tool-tip").slideUp(200, () => {
            setDescription("");
        });
    }

    useEffect(() => {
        if (showSheet) {
            $("#tool-tip").slideDown(300);
            $("#tool-tip-overlay").fadeIn(200);
        } else {
            // $("#tool-tip").slideUp()
        }
    }, [description]);

    useEffect(() => {
        $(document).unbind("click").on("click", ".question", onClickQuestion);
    });

    return (
        <>
            <div id="tool-tip" className="tool-tip container">
                <div className="position-relative p-6 pt-10">
                    <p className="mb-0" dangerouslySetInnerHTML={createMarkup(description)} />
                    <span onClick={() => onHide()} className="bottom-sheet-close pt-3 pr-4">
                        X
                    </span>
                </div>
            </div>
            <div id="tool-tip-overlay" className="tool-tip-overlay" onClick={() => onHide()} />
        </>
    );
}

export default ToolTipBottomSheet;
