/* eslint-disable no-useless-escape */

import Cookies from "js-cookie";
import moment from "moment";
import { UAParser } from "ua-parser-js";
import jwtPkg from "jsonwebtoken";

const transform = require("lodash").transform;
const isEqual = require("lodash").isEqual;
const isObject = require("lodash").isObject;

import { genderOptions } from "i18n/messages/dropdownFieldOptions";
import { extra } from "i18n/messages/index";
import { getBrokerDocument } from "api";

import {
    ADMIN_ROUTE_WITH_TOKEN,
    BROKER_ROUTE_WITH_TOKEN,
    TENANT_ROUTE_WITH_TOKEN,
    ISSUER_ROUTE_WITH_TOKEN,
    CLUBDEALS_ROUTE_WITH_TOKEN,
    EMAIL_CONFIRMED_PROVIDER_STATUS,
    UMBRELLA_ROUTE_WITH_TOKEN,
    statusLabelMapper,
    DEVICE_OS,
    USER_REGISTRATION_ONBOARDING,
    PARTNER_ROUTE_WITH_TOKEN,
} from "constants/index";
import { TILE_STATUS } from "constants/status";
import { getAppleWalletURL, checkValidString } from "common/helper";

const FINX_COOKIE = require("common/constants");
const FINX_USER_ROLES = require("constants/roles");

Date.prototype.isValid = function () {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.

    return this.getTime() === this.getTime();
};

const isServer = typeof window === "undefined";
const isClient = () => typeof window === "object";

export const addDashString = (data) => (data === "" || data === null || data === undefined ? "-----" : "" + data);

export function formatFloat(locale, number) {
    number = "" + number;
    if (locale === "de") {
        return number.replace(".", ",");
    }
    return number.replace(",", ".");
}

export function formatDateWithLocale(locale = "de", date) {
    if (locale === "de") {
        return date ? moment(new Date(date)).format("DD.MM.YYYY") : null;
    }
    return date ? moment(new Date(date)).format("YYYY-MM-DD") : null;
}

function formatGermanNumber(x, fixed = 2) {
    if (typeof x === "string") {
        x = parseFloat(x);
    } else if (typeof x === "boolean") {
        return;
    } else if (x == undefined || x == null) {
        return;
    }
    return x
        .toFixed(fixed)
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function formatEnglishNumber(x, fixed = 2) {
    if (typeof x === "string") {
        x = parseFloat(x);
    } else if (typeof x === "boolean") {
        return;
    } else if (x == undefined || x == null) {
        return;
    }
    return x
        .toFixed(fixed)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatLocaleNumber(locale, number, fixed = 2) {
    if (typeof number === "string") return 0;

    if (locale === "en") {
        return number
            .toFixed(fixed)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return number
        .toFixed(fixed)
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function formatNoDecimalNumber(x, fixed = 2) {
    if (x) {
        if (typeof x === "string") {
            x = parseFloat(x);
        } else if (typeof x === "boolean") {
            return;
        }

        if (typeof x === "number") {
            return x
                .toFixed(fixed)
                .toString()
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    }
}

function addGermanDecimal(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function addToAppleWalletUrl(assetID) {
    return getAppleWalletURL(assetID);
}

function getQueryString(key) {
    if (isServer || !key) {
        return "";
    }
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"),
            "$1",
        ),
    );
}

const isMobileBrowser = (vendor) => {
    return !!(
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            vendor,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            vendor.substr(0, 4),
        )
    );
};

function checkBrowserNotSupported(ua = null) {
    var sUsrAg = ua ? ua : !isServer && window.navigator && (navigator.userAgent || navigator.vendor || window.opera);

    if (sUsrAg.indexOf("Mobile") > -1 || sUsrAg.indexOf("mobile") > -1 || isMobileBrowser(sUsrAg)) {
        return false;
    } else {
        var browserString = "",
            browser = "",
            version,
            MINIMUM_SUPPORTED_BROWSER_VERSION = {
                Firefox: 51,
                Chrome: 49,
                Safari: 10,
                Opera: 37,
                Edge: 14,
            };

        if (!sUsrAg) return true;

        if (sUsrAg.indexOf("Firefox") > -1) {
            browser = browserString = "Firefox";
        } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
            browser = browserString = "SamsungBrowser";
        } else if (sUsrAg.indexOf("UCBrowser") > -1) {
            return false;
        } else if (sUsrAg.indexOf("Opera") > -1) {
            browser = browserString = "Opera";
        } else if (sUsrAg.indexOf("OPR") > -1) {
            browser = "Opera";
            browserString = "OPR";
        } else if (sUsrAg.indexOf("MSIE3 ") > -1 || sUsrAg.indexOf("Trident") > -1) {
            return true;
        } else if (sUsrAg.indexOf("Edge") > -1) {
            browser = browserString = "Edge";
        } else if (sUsrAg.indexOf("Edg") > -1) {
            browser = "Edge";
            browserString = "Edg";
        } else if (sUsrAg.indexOf("Chrome") > -1) {
            browser = browserString = "Chrome";
        } else if (sUsrAg.indexOf("Safari") > -1) {
            browser = "Safari";
            browserString = "Version";
        } else {
            return false;
        }

        sUsrAg =
            (sUsrAg &&
                Array.isArray(sUsrAg.split(`${browserString}/`)) &&
                sUsrAg.split(`${browserString}/`)[1] &&
                Array.isArray(sUsrAg.split(`${browserString}/`)[1].split(" ")) &&
                Array.isArray(sUsrAg.split(`${browserString}/`)[1].split(" ")[0].split(".")) &&
                sUsrAg.split(`${browserString}/`)[1].split(" ")[0].split(".")[0]) ||
            0;

        version = parseInt(sUsrAg);
        return version <= MINIMUM_SUPPORTED_BROWSER_VERSION[browser];
    }
}

function calculateAge(birthday) {
    var today = new Date();
    var birthDate = new Date(birthday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }
    return age >= 18 && age <= 110;
}

function createMarkup(__html) {
    return { __html };
}

function validateObject(object = {}, targetKeysArray = []) {
    targetKeysArray.map((key) => {
        if (key in object === false || object[key] === undefined || object[key] === null) {
            object = { ...object, [key]: "" };
        }
    });
    return object;
}

function differenceBetweenObj(object, base) {
    return transform(object, (result, value, key) => {
        if (!isEqual(value, base[key])) {
            result[key] = isObject(value) && isObject(base[key]) ? differenceBetweenObj(value, base[key]) : value;
        }
    });
}

function downloadBlobFile(blob, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement("a");
    link.setAttribute("download", fileName);
    link.setAttribute("href", data);

    document.body.appendChild(link);
    link.click();

    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        document.body.removeChild(link);
    }, 100);
}

function saveByteArray(arrayBuffer, fileName, mimeType = "application/octet-stream", callback) {
    const byteArray = new Uint8Array(arrayBuffer);
    setTimeout(function () {
        const blob = new Blob([byteArray], { type: mimeType });

        if (
            window.outerWidth <= 768 &&
            (navigator.userAgent.toLowerCase().indexOf("fxios") > -1 || typeof navigator.brave !== "undefined")
        ) {
            downloadBlobFile(blob, fileName);
        } else {
            require("./download")(blob, fileName, mimeType);
        }

        typeof callback === "function" && callback();
    }, 300);
}

function isValidHttpUrl(url) {
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
}

function isArrayBuffer(buffer) {
    return buffer && buffer instanceof ArrayBuffer && buffer.byteLength !== undefined;
}

function openDownloadFileNewTab(arrayBuffer, type, callback) {
    // Save byte array to a blob
    const byteArray = new Uint8Array(arrayBuffer);
    const blob = new Blob([byteArray], { type: type || "application/pdf" });

    // Create object URL for the blob
    const url = URL.createObjectURL(blob);

    // Open the PDF in a new tab
    window.open(url, "_blank");

    typeof callback === "function" && callback();
}

function saveFile(data, fileName, type = "application/pdf", isToken = false, isFileOpen = false, isFnxtToken = false) {
    if (isArrayBuffer(data)) {
        return new Promise((resolve) => {
            saveByteArray(data, fileName, type, () => resolve());
        });
    } else if (isValidHttpUrl(data)) {
        const finexityTenant = Cookies.get(FINX_COOKIE.FINEXITY_TENANT);
        const headers = { responseType: "arraybuffer", "finexity-tenant": finexityTenant };

        if (isFnxtToken) {
            const accessToken = getAccessToken();
            headers.Authorization = `Bearer ${accessToken}`;
        } else if (isToken) {
            const idToken = getIdToken();
            headers.Authorization = `Bearer ${idToken}`;
        }

        let options = null;
        const isPublicFile = data.includes("/docs/") || data.includes("files.");

        if (!isPublicFile) options = { headers };

        const finalFileName = fileName || data.substring(data.lastIndexOf("/") + 1);

        return new Promise((resolve, reject) => {
            fetch(data, options)
                .then((response) => {
                    if (response && response.ok) {
                        response
                            .arrayBuffer()
                            .then((arrayBuffer) =>
                                isFileOpen
                                    ? openDownloadFileNewTab(arrayBuffer, type, () => resolve())
                                    : saveByteArray(arrayBuffer, finalFileName, type, () => resolve()),
                            )
                            .catch(() => reject());
                    } else reject();
                })
                .catch(() => reject());
        });
    } else {
        return new Promise((_, reject) => {
            reject();
        });
    }
}

export async function downloadJsonFile(fileName, data) {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function isValidDateFormat(dateString) {
    return new Date(dateString).isValid();
}

function splitDateStringForProperDate(str) {
    const twoString = str && str.split("_");
    const firstArray = twoString && twoString[0] && twoString[0].split("-");
    if (firstArray) {
        firstArray[1] = "" + (firstArray[1] - 1);
    }
    const secondArray = twoString && twoString[1] && twoString[1].split("-");
    return secondArray ? firstArray.concat(secondArray) : firstArray;
}

export function getDateFromDBDateString(dateString) {
    if (isValidDateFormat(dateString)) return new Date(dateString);
    return new Date(...splitDateStringForProperDate(dateString));
}

function formateDate(d, str1, str2, str3, jointer) {
    if (!(d instanceof Date)) {
        return "";
    }
    const date = {
        date: d.getDate() < 10 ? `0${d.getDate()}` : d.getDate(),
        month: d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
        year: d.getFullYear(),
    };
    return date[str1] + jointer + date[str2] + jointer + date[str3];
}

export function convertDBDateToDefaultFormat(dateString) {
    if (isValidDateFormat(dateString)) return new Date(dateString);
    const parsedDate =
        dateString &&
        dateString
            .split("_")
            .map((item, index) => {
                if (index === 0) return item;
                return item.replace(/-/g, ":") + ".000Z";
            })
            .join("T");
    return isValidDateFormat(parsedDate) ? new Date(parsedDate) : new Date();
}

function sanitize(string) {
    const map = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#x27;",
        "/": "&#x2F;",
    };
    const reg = /[&<>"'/]/gi;
    return typeof string === "string" ? string.replace(reg, (match) => map[match]) : string;
}

function addOverlay(className = "") {
    window.outerWidth < 992 && jQuery("body").addClass("overflow-hidden");
    if (window.outerWidth > 991) {
        className ? jQuery("#__next").addClass(className) : jQuery("#__next").addClass("bg-overlay");
    }
}

function removeOverlay(className = "") {
    jQuery("body").removeClass("overflow-hidden");
    className ? jQuery("#__next").removeClass(className) : jQuery("#__next").removeClass("bg-overlay");
}

function sideModalScrollHidden(open) {
    if (window.outerWidth > 991) {
        open ? jQuery("body").addClass("scroll-hidden") : jQuery("body").removeClass("scroll-hidden");
    }
}

function setEqualHeight(element) {
    var cards = document.getElementsByClassName(element);
    var heights = [];
    var i;
    for (i = 0; i < cards.length; i++) {
        heights.push(cards[i].clientHeight);
    }
    var maxHeight = Math.max(...heights);
    for (i = 0; i < cards.length; i++) {
        cards[i].setAttribute("style", "height: " + maxHeight + "px");
    }
}

function dateCompare(asc, field, nestedOperator = "") {
    const indices = [6, 7, 8, 9, 3, 4, 0, 1],
        brr = nestedOperator ? field.split(nestedOperator) : [field];

    return (a, b) => {
        let first,
            second,
            r = 0;
        (first = a[brr[0]]), (second = b[brr[0]]);
        for (let i = 1, length = brr.length; i < length; i++) {
            first = first[brr[i]];
            second = second[brr[i]];
        }
        if (asc) {
            indices.find((i) => (r = first.charCodeAt(i) - second.charCodeAt(i)));
        } else {
            indices.find((i) => (r = second.charCodeAt(i) - first.charCodeAt(i)));
        }
        return r;
    };
}

export function dateComparison(first, second, dateFormat = "yyyy-mm-dd") {
    let indices,
        r = 0;

    if (dateFormat === "yyyy-mm-dd") {
        indices = [0, 1, 2, 3, 5, 6, 8, 9];
    } else if (dateFormat === "dd-mm-yyyy") {
        indices = [6, 7, 8, 9, 3, 4, 0, 1];
    } else if (dateFormat === "mm-dd-yyyy") {
        indices = [6, 7, 8, 9, 0, 1, 3, 4];
    } else {
        return new Error();
    }

    indices.find((i) => (r = first.charCodeAt(i) - second.charCodeAt(i)));

    return r;
}

function stringCompare(asc, field, nestedOperator = "") {
    const brr = nestedOperator ? field.split(nestedOperator) : [field];
    return (a, b) => {
        let first, second;
        (first = a[brr[0]]), (second = b[brr[0]]);
        for (let i = 1, length = brr.length; i < length; i++) {
            first = first[brr[i]];
            second = second[brr[i]];
        }
        return asc ? first && first.localeCompare(second) : second && second.localeCompare(first);
    };
}

export const brokerPdfDownload = (path, Filename) => {
    return new Promise((resolve, reject) => {
        getBrokerDocument(path, Filename)
            .then((response) => {
                if (response && response.ok) {
                    response.arrayBuffer().then((arrayBuffer) => {
                        saveByteArray(arrayBuffer, Filename, "application/octet-stream", () => resolve());
                    });
                } else reject();
            })
            .catch(() => reject());
    });
};

function stringNumberCompare(asc, field, nestedOperator = "") {
    const brr = nestedOperator ? field.split(nestedOperator) : [field];

    return (a, b) => {
        let first, second;
        (first = a[brr[0]]), (second = b[brr[0]]);
        for (let i = 1, length = brr.length; i < length; i++) {
            first = first[brr[i]];
            second = second[brr[i]];
        }
        first = parseFloat(first);
        second = parseFloat(second);

        if (asc) {
            if (first > second) {
                return 1;
            } else if (first < second) {
                return -1;
            } else return 0;
        } else {
            if (first < second) {
                return 1;
            } else if (first > second) {
                return -1;
            } else return 0;
        }
    };
}

function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export const replaceDashWithCapitalize = (str) => {
    // str = str.split("-");

    // for (var i = 0, x = str.length; i < x; i++) {
    //     str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    // }

    // return str.join(" ");
    return str.replace("-", " ").replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
};

export function convertTimeStamp(timeStamp) {
    var ms_Min = 60000; // milliseconds in Minute
    var ms_Hour = 3600000; // milliseconds in Hour
    var ms_Day = 86400000; // milliseconds in day
    var ms_Mon = 2592000000; // milliseconds in Month
    var ms_Yr = 946080000000; // milliseconds in Year

    if (timeStamp < ms_Min) {
        return Math.round(timeStamp / 1000) + " seconds ago";
    } else if (timeStamp < ms_Hour) {
        return Math.round(timeStamp / ms_Min) + " minutes ago";
    } else if (timeStamp < ms_Day) {
        return Math.round(timeStamp / ms_Hour) + " hours ago";
    } else if (timeStamp < ms_Mon) {
        return Math.round(timeStamp / ms_Day) + " days ago";
    } else if (timeStamp < ms_Yr) {
        return Math.round(timeStamp / ms_Mon) + " months ago";
    } else {
        return Math.round(timeStamp / ms_Yr) + " years ago";
    }
}

export function timeDifference(curr, prev) {
    return convertTimeStamp(curr - prev);
}

export const getFirstBrowserLanguage = () => {
    const defaultLanguage = "de";
    const supportedLanguage = ["en", "de"];

    if (isClient()) {
        var browserPreferredLanguage;

        // support for other well known properties in browsers
        const browserLanguagePropertyKeys = ["language", "browserLanguage", "systemLanguage", "userLanguage"];
        for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
            browserPreferredLanguage = window.navigator[browserLanguagePropertyKeys[i]];
            if (browserPreferredLanguage && browserPreferredLanguage.length) break;
        }

        browserPreferredLanguage = supportedLanguage.find((language) => browserPreferredLanguage.includes(language));
        if (browserPreferredLanguage) return browserPreferredLanguage;

        // support for HTML 5.1 "navigator.languages"
        var browserLanguages = window.navigator.languages;
        if (Array.isArray(browserLanguages)) {
            const germanLanguageIndex = browserLanguages.findIndex((language) => language.includes("de"));
            const englishLanguageIndex = browserLanguages.findIndex((language) => language.includes("en"));
            if (germanLanguageIndex < 0 && englishLanguageIndex < 0) return defaultLanguage;
            if (germanLanguageIndex < 0 || englishLanguageIndex < 0) {
                return browserLanguages[Math.max(englishLanguageIndex, germanLanguageIndex)];
            }
            return browserLanguages[Math.min(englishLanguageIndex, germanLanguageIndex)];
        }
    }

    return defaultLanguage;
};

export const getTitles = (formatMessage) => [
    {
        value: "",
        label: formatMessage(extra.pleaseSelect),
    },
    {
        value: "dr",
        label: "Dr.",
    },
    {
        value: "prof",
        label: "Prof.",
    },
    {
        value: "prof-dr",
        label: "Prof. Dr.",
    },
];

export const getGenders = (formatMessage) => [
    {
        value: "",
        label: formatMessage(extra.pleaseSelect),
    },
    {
        value: "female",
        label: formatMessage(genderOptions.first),
    },
    {
        value: "male",
        label: formatMessage(genderOptions.second),
    },
    {
        value: "diverse",
        label: formatMessage(genderOptions.third),
    },
];

export const statusLabelMaker = (statuses) => {
    const arr = [];

    statuses.map((status) => {
        arr.push(statusLabelMapper[status]);
    });

    return arr;
};

export const breakCamelCaseWord = (word = "") => {
    let indi = 0,
        str = "";
    const len = word.length;
    for (let i = 0; i < len; i++) {
        if (
            word.charCodeAt(i) >= 65 &&
            word.charCodeAt(i) <= 90 &&
            i > 0 &&
            !(word.charCodeAt(i - 1) >= 65 && word.charCodeAt(i - 1) <= 90)
        ) {
            str += word[indi].toUpperCase() + word.slice(indi + 1, i) + " ";
            indi = i;
        }
    }
    str += word[indi].toUpperCase() + word.slice(indi + 1, len);
    return str;
};

export const copyToClipboard = (text) => {
    var el = document.createElement("textarea");
    el.value = text;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
};

export const getCommonArrayElements = (arr1, arr2) => {
    var common = [];
    for (var i = 0; i < arr1.length; ++i) {
        for (var j = 0; j < arr2.length; ++j) {
            if (arr1[i] == arr2[j]) {
                common.push(arr1[i]);
            }
        }
    }
    return common;
};

export const extractRolesByTenant = (roles, tenantList) => {
    let common = {};
    tenantList.map((tenant) => {
        roles.map((role) => {
            if (role.split(".")[0] === tenant) {
                if (common[tenant]) common = { ...common, [tenant]: [...common[tenant], role.replace(`${tenant}.`, "")] };
                else common = { ...common, [tenant]: [role.replace(`${tenant}.`, "")] };
            }
        });
    });
    return common;
};

export function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function isLightColor(color) {
    const c = color.substring(1),
        rgb = parseInt(c, 16),
        r = (rgb >> 16) & 0xff,
        g = (rgb >> 8) & 0xff,
        b = (rgb >> 0) & 0xff,
        luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luma > 128 ? true : false;
}

export function getInitialsFromEmail(email) {
    return (
        email &&
        email
            .substring(0, email.lastIndexOf("@"))
            .match(/\b(\w)/g)
            .join("")
            .toUpperCase()
    );
}

export function getInitialsFromName(name) {
    let initials = (name && name.match(/\b\w/g)) || [];
    initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    return initials;
}

function getRegionFormattedDate(formatDate = "") {
    const lang = Cookies.get(FINX_COOKIE.LOCALE);
    const regionCode = lang === "de" ? "de-DE" : "en-GB";
    return new Intl.DateTimeFormat(regionCode, { year: "numeric", month: "2-digit", day: "2-digit" }).format(formatDate);
}

const toGermanCurrency = (amount) => {
    let resetAmount = amount === "" ? "0" : amount;
    resetAmount = resetAmount.split(".").join("").replace(",", "");
    resetAmount = parseFloat(resetAmount) / 100;
    resetAmount = resetAmount && resetAmount.toFixed(2);
    let formattedAmount = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 }).format(resetAmount);
    formattedAmount = Number.isNaN(formattedAmount) || formattedAmount === "0" ? "0,00" : formattedAmount;
    return {
        formattedAmount,
        resetAmount,
    };
};

const toEnglishCurrency = (amount) => {
    let resetAmount = amount === "" ? "0" : amount;
    resetAmount = resetAmount.split(",").join("").replace(".", "");
    resetAmount = parseFloat(resetAmount) / 100;
    resetAmount = resetAmount && resetAmount.toFixed(2);
    let formattedAmount = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(resetAmount);
    formattedAmount = Number.isNaN(formattedAmount) || formattedAmount === "0" ? "0.00" : formattedAmount;
    return {
        formattedAmount,
        resetAmount,
    };
};

export function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export const checkEmailIsConfirmed = (status) => {
    return (
        status === EMAIL_CONFIRMED_PROVIDER_STATUS.FACEBOOK ||
        status === EMAIL_CONFIRMED_PROVIDER_STATUS.APPLE ||
        status === EMAIL_CONFIRMED_PROVIDER_STATUS.LINKEDIN ||
        status === EMAIL_CONFIRMED_PROVIDER_STATUS.GOOGLE ||
        status === TILE_STATUS.SUCCESS ||
        status === TILE_STATUS.HIDE
    );
};

export const checkUserOnboardingStatus = (UserRegistrationOnboardingStatus) => {
    return USER_REGISTRATION_ONBOARDING[UserRegistrationOnboardingStatus];
};

export const formatPhoneNumber = (phoneNumber) => {
    var match = phoneNumber.match(/^(0)([0-9]*)$/);
    if (match) {
        return ["(", match[1], ")", match[2]].join("");
    }
    return phoneNumber;
};

export const removeParentheses = (str) => {
    return str.replace(/\(|\)/gi, function () {
        return "";
    });
};

export const getOriginNumber = (phoneNumber) => {
    var match = phoneNumber.match(/^(\(0\))?([0-9]*)$/);
    if (match) {
        return match[2] ? match[2] : "";
    }
    return phoneNumber;
};

export const isElementExitInList = (arrayList, ele) => {
    return !!(Array.isArray(arrayList) && arrayList.find((_ele) => _ele === ele));
};

export const forceActiveAdminLink = (link) => isElementExitInList(ADMIN_ROUTE_WITH_TOKEN, link);
export const forceActiveBrokerLink = (link) => isElementExitInList(BROKER_ROUTE_WITH_TOKEN, link);
export const forceActivePartnerLink = (link) => isElementExitInList(PARTNER_ROUTE_WITH_TOKEN, link);
export const forceActiveClubDealLink = (link) => isElementExitInList(CLUBDEALS_ROUTE_WITH_TOKEN, link);
export const forceActiveTenantLink = (link) => isElementExitInList(TENANT_ROUTE_WITH_TOKEN, link);
export const forceActiveIssuerLink = (link) => isElementExitInList(ISSUER_ROUTE_WITH_TOKEN, link);
export const forceActiveUmbrellaLink = (link) => isElementExitInList(UMBRELLA_ROUTE_WITH_TOKEN, link);

export const isEmpty = (obj) => {
    return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
};

export const msToTime = (duration) => {
    var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
};

export const formatSecondsIntoDuration = (seconds) => {
    let str = "";

    if (seconds > 59) {
        const val = Math.floor(seconds / 60);
        str = "" + val + ":";
        seconds = seconds - 60 * val;
    } else {
        str = "00:";
    }

    if (seconds > 0) {
        str += seconds < 10 ? "0" + seconds : seconds;
    } else {
        str += "00";
    }

    str += " mins";
    return str;
};

export function getParameterByNameFromUrl(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const convertStringToAsterisk = (string) =>
    string
        ?.split("")
        .map(() => "*")
        .toString()
        .replaceAll(",", "");

export function insertAtArray(array, index, ...elements) {
    return array.splice(index, 0, ...elements);
}

//find and Add new Item based on uid
Array.prototype.findAndReplace = function (item) {
    const index = this.findIndex((e) => e.uid == item.uid);
    if (index || index == 0) {
        this[index] = item;
        return this;
    }
};

Array.prototype.groupSort = function () {
    return this.sort?.((_a, _b) => parseInt(_a.SortingNumber) - parseInt(_b.SortingNumber));
};

Array.prototype.replaceItemBy = function (uid, replacement) {
    return this.map((item) => {
        if (Array.isArray(item)) {
            return this.replaceItemBy(uid, replacement);
        } else if (item?.uid == uid) {
            return replacement;
        } else if (item?.Subgroups?.length) {
            item.Subgroups = item?.Subgroups?.replaceItemBy(uid, replacement);
            return item;
        } else {
            return item;
        }
    });
};

const stringToHTML = function (str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, "text/html");
    return doc.body.innerText;
};

function injectNoScript(source) {
    const newItem = document.createElement("noscript");
    const textnode = document.createTextNode(
        source.search("noscript") > -1 ? source?.split("<noscript>")[1]?.split("</noscript>")[0] || "" : source,
    );
    newItem.appendChild(textnode);

    document.body.insertBefore(newItem, document.body.firstChild);
}

function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    return previous;
}

const findRootDomain = (url) => {
    const r = /.*\.([^.]*[^0-9][^.]*\.[^.]*[^.0-9][^.]*$)/;
    return `https://${url.replace(r, "$1")}`;
};

function getHashQueryStringParam(paramName) {
    const params = new URLSearchParams(window.location.hash.substr(1));
    return checkValidString(params.get(paramName)) ? params.get(paramName) : null;
}

function parseAuth0JwtToken(token) {
    try {
        const decodedToken = jwtPkg.decode(token, { complete: true });
        return decodedToken?.payload;
    } catch {
        return false;
    }
}

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

function getMobileOperatingSystem() {
    var userAgent = window?.opera || navigator?.userAgent || navigator?.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return DEVICE_OS.WINDOWS;
    }

    if (/android/i.test(userAgent)) {
        return DEVICE_OS.ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return DEVICE_OS.iOS;
    }

    return DEVICE_OS.UNKNOWN;
}

export function showAppleWalletBasedOnOS() {
    return getMobileOperatingSystem() == DEVICE_OS.iOS;
}

const getIdToken = () => isClient() && localStorage.getItem(FINX_COOKIE.FINX_SESSION);

const getAccessToken = () => isClient() && localStorage.getItem(FINX_COOKIE.FINX_ACCESS_TOKEN);

const getRefreshToken = () => isClient() && localStorage.getItem(FINX_COOKIE.REFRESH_TOKEN);

const getUserEmail = () => isClient() && Cookies.get(FINX_COOKIE.USER_EMAIL);

const parseRolesFromCookie = () => {
    const unFilteredRoles = isClient() && Cookies.get(FINX_COOKIE.USER_ROLE);
    return typeof unFilteredRoles === "string"
        ? unFilteredRoles
              .split(",")
              .filter((item) => Object.values(FINX_USER_ROLES).find((role) => role === item))
              .toString()
        : "";
};

const truncateString = (value, maxLength) => (value?.length > maxLength ? `${value.substring(0, maxLength)}...` : value);

const getTenantAssets = (documentHost, tenant) => {
    const randomNumber = new Date().valueOf();
    const baseTenantAssetsPath = `${documentHost}/docs/tenant/${tenant}`;
    return {
        tenantLogo: `${baseTenantAssetsPath}/assets/logo.png?v=${randomNumber}`,
        tenantCss: `${baseTenantAssetsPath}/assets/main.css?v=${randomNumber}`,
        tenantFavicon: `${baseTenantAssetsPath}/assets/favicon.ico?v=${randomNumber}`,
        tenantOGImage: `${baseTenantAssetsPath}/assets/ogimage.jpg?v=${randomNumber}`,
        tenantMarketPlaceFile: `${baseTenantAssetsPath}/termsandconditions/marketplace.pdf?v=${randomNumber}`,
    };
};

const getPlatformTenantOldAssets = (documentHost, tenant) => {
    const randomNumber = new Date().valueOf();
    const baseTenantAssetsPath = `${documentHost}/docs/tenant/${tenant}`;
    return {
        tenantOldMarketPlaceFile: `${baseTenantAssetsPath}/termsandconditions/old/marketplace.pdf?v=${randomNumber}`,
        platformOldFile: `${documentHost}/docs/platform/termsandconditions/old/plattform.pdf?v=${randomNumber}`,
    };
};

const getPlatformAssets = (documentHost) => {
    const randomNumber = new Date().valueOf();
    return {
        platform: `${documentHost}/docs/platform/termsandconditions/plattform.pdf?v=${randomNumber}`,
        effecta: `${documentHost}/docs/platform/termsandconditions/effecta.pdf?v=${randomNumber}`,
    };
};

const getTimeDifference = (dateString) => {
    const formattedDate = formatDateWithUTC(dateString);
    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const difference = currentDate - new Date(formattedDate);

    // Convert milliseconds to minutes
    const minutes = Math.floor(difference / 60000);

    if (minutes < 2) {
        return "Just now";
    } else if (minutes < 60) {
        return `${minutes} min ago`;
    } else if (minutes < 1440) {
        const hours = Math.floor(minutes / 60);
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes < 43800) {
        // Less than 1 month (30 days)
        const days = Math.floor(minutes / 1440);
        return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (minutes < 525600) {
        // Less than 1 year (365 days)
        const months = Math.floor(minutes / 43800);
        return `${months} month${months > 1 ? "s" : ""} ago`;
    } else {
        const years = Math.floor(minutes / 525600);
        return `${years} year${years > 1 ? "s" : ""} ago`;
    }
};

const formatDateWithUTC = (dateString) => {
    const datePart = dateString.split("_")?.[0];
    const timePart = dateString.split("_")?.[1]?.replace(/-/g, ":");

    return `${datePart}T${timePart}Z`;
};

function convertToRgb(color, opacity = 1) {
    // Helper function to convert a named color to an rgb string
    function getRgbFromColorName(colorName) {
        const tempElem = document.createElement("div");
        tempElem.style.color = colorName;
        document.body.appendChild(tempElem);
        const rgbValue = window.getComputedStyle(tempElem).color;
        document.body.removeChild(tempElem);
        return rgbValue;
    }

    // If it's a named color, convert it to rgb() format
    if (!color.startsWith("#") && !color.startsWith("rgb")) {
        color = getRgbFromColorName(color);
    }

    // Helper function to blend with white based on opacity
    function blendWithWhite(r, g, b, opacity) {
        const white = 255;
        r = Math.round(r * opacity + white * (1 - opacity));
        g = Math.round(g * opacity + white * (1 - opacity));
        b = Math.round(b * opacity + white * (1 - opacity));
        return `rgb(${r}, ${g}, ${b})`;
    }

    // Check if it's a hex code
    if (color.startsWith("#")) {
        let hex = color.replace("#", "");

        // If the shorthand form (#abc), expand it to full form (#aabbcc)
        if (hex.length === 3) {
            hex = hex
                .split("")
                .map((char) => char + char)
                .join("");
        }

        // Convert to RGB values
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Blend the color with white based on opacity
        return blendWithWhite(r, g, b, opacity);
    }

    // Check if it's already in rgb() or rgba() format
    if (color.startsWith("rgb")) {
        // Extract the rgb values
        const rgbValues = color.match(/\d+/g).map(Number);
        const [r, g, b] = rgbValues;

        // Blend the color with white based on opacity
        return blendWithWhite(r, g, b, opacity);
    }

    // If it's not a valid format, return null or an error message
    return null;
}

const checkIsSafariBrowser = () => {
    if (isClient()) {
        const parser = new UAParser(navigator.userAgent || navigator.vendor || window.opera);
        return parser.getBrowser().name.toLowerCase() === "safari";
    }
    return false;
};

const isInheritTokenExpired = () => {
    const storedTime = parseInt(localStorage.getItem(FINX_COOKIE.INHERIT_USER_TOKEN_EXPIRATION), 10);
    const currentTime = moment();

    return currentTime.valueOf() > storedTime;
};

const isTokenExpiringSoon = (token) => {
    const payload = parseAuth0JwtToken(token);
    // if there is invalid token
    if (!payload) return true;

    const expirationTime = payload.exp * 1000;
    const currentTime = Date.now();

    return expirationTime - currentTime <= 5 * 60 * 1000;
};

const isTokenExpired = (token) => {
    const payload = parseAuth0JwtToken(token);
    // if there is invalid token
    if (!payload) return true;

    const expirationTime = payload.exp * 1000;
    const currentTime = Date.now();

    return currentTime > expirationTime;
};

function obfuscateEmail(email) {
    // Split the email into local part and domain part
    const [localPart, domainPart] = email.split("@");
    const [domainName, domainExtension] = domainPart.split(".");

    // Format local part
    const obfuscatedLocal = `${localPart[0]}**.*****${localPart.slice(-2)}`;

    // Format domain part
    const obfuscatedDomain = `${domainName[0]}**${domainName.slice(-1)}.${domainExtension}`;

    // Return the formatted email
    return `${obfuscatedLocal}@${obfuscatedDomain}`;
}

function calculateTotalCommission(commissions) {
    return commissions.reduce((total, item) => total + parseFloat(item.commission || 0), 0);
}

function calculateInvestmentsTotal(commissions) {
    return commissions.reduce((total, item) => total + parseFloat(item.amount || 0), 0);
}

function calculateAverageInvestment(commissions) {
    const totalAmount = calculateInvestmentsTotal(commissions);
    return commissions.length > 0 ? totalAmount / commissions.length : 0;
}

function calculateTotalInvestors(commissions) {
    const uniqueInvestors = new Set(commissions.map((item) => item.investorId));
    return uniqueInvestors.size;
}
function calculateCommissionByStatus(commissions, status) {
    return commissions
        .filter((item) => item.status === status)
        .reduce((total, item) => total + parseFloat(item.commission || 0), 0);
}

export {
    isClient,
    getTenantAssets,
    parseRolesFromCookie,
    getUserEmail,
    getIdToken,
    parseAuth0JwtToken,
    getHashQueryStringParam,
    injectNoScript,
    stringToHTML,
    createMarkup,
    formateDate,
    stringNumberCompare,
    validateObject,
    calculateAge,
    checkBrowserNotSupported,
    formatGermanNumber,
    formatEnglishNumber,
    formatNoDecimalNumber,
    addGermanDecimal, //
    getQueryString,
    differenceBetweenObj,
    saveFile,
    splitDateStringForProperDate,
    capitalizeFirstLetter,
    sanitize,
    addOverlay,
    removeOverlay,
    setEqualHeight,
    dateCompare,
    stringCompare,
    sideModalScrollHidden,
    getRegionFormattedDate,
    toGermanCurrency,
    toEnglishCurrency,
    findRootDomain,
    formatBytes,
    getPreviousDay,
    truncateString,
    getTimeDifference,
    formatDateWithUTC,
    getPlatformAssets,
    getPlatformTenantOldAssets,
    convertToRgb,
    getAccessToken,
    checkIsSafariBrowser,
    isInheritTokenExpired,
    isTokenExpiringSoon,
    isTokenExpired,
    getRefreshToken,
    obfuscateEmail,
    calculateTotalCommission,
    calculateInvestmentsTotal,
    calculateAverageInvestment,
    calculateTotalInvestors,
    calculateCommissionByStatus,
};
