import { put, all, takeLatest, call, select } from "redux-saga/effects";
const pick = require("lodash/pick");

import AccountActionTypes from "./types";

import {
    fetchUserDetailsSuccess,
    fetchUserDetailsFailure,
    setUserMobileDetailsSuccess,
    setUserMobileDetailsFailure,
    userDashboardDetailsSuccess,
    userDashboardDetailsFailure,
    fetchUserAllDetailsSuccess,
    fetchUserAllDetailsFailure,
    fetchPayInWalletBalanceSuccess,
    fetchPayInWalletBalanceFailure,
    fetchBlockedWalletBalanceSuccess,
    fetchBlockedWalletBalanceFailure,
    fetchUserPaymentsSuccess,
    fetchUserPaymentsFailure,
    fetchUserMinPayInAmountSuccess,
    fetchUserMinPayInAmountFailure,
    updateUserTaxDataTransitions,
    updateUserData,
    fetchUserTermsAndConditionSuccess,
    fetchUserSubscriptionsSuccess,
    fetchUserSubscriptionsFailure,
} from "./actions";

import { callTheInheritUserInitialKYCMethod } from "store/user/kyc/actions";

import { setInvestmentStepData } from "store/user/investment/actions";

import { setAdequacyStart, fetchAdequacySuccess, fetchAdequacyFailure } from "store/user/adequacy/actions";

import {
    fetchUserDetails,
    checkMangoPayBalance,
    setUserMobileDetails,
    // setTipsterId,
    fetchPaymentsForAUser,
    checkMinPayInAmount,
    setUserTaxData,
    getUserSubscriptions,
} from "api";

import {
    investmentServicesYearsOptionsMapping,
    yearsOptionsMapping,
    transactionYearsOptionsMapping,
    transactionSizesOptionsMapping,
} from "constants";

const FINX_COOKIE = require("common/constants");
import { USER_ROLES, RESPONSE_ERRORS } from "constants/index";
import { extractKYCPrefilledData, extractUserAdditionalData } from "store/helpers";
import { updateKYCFormData } from "../kyc/actions";
import { getUserInitiated } from "../kyc/selectors";

function* fetchUserAllDetailsSaga({ isGetInheritUserKyc }) {
    const cookiesPkg = require("js-cookie");
    try {
        const response = yield call(fetchUserDetails);

        const inheritUserToken = cookiesPkg.get(FINX_COOKIE.INHERIT_USER_TOKEN);
        const inheritUserEmail = cookiesPkg.get(FINX_COOKIE.INHERIT_USER_EMAIL);
        const isInheritUserEnabled = !!(inheritUserToken && inheritUserEmail);
        if (
            response?.data?.errors?.[0]?.message === RESPONSE_ERRORS.EMAIL_NOT_FOUND_IN_TOKEN ||
            (!response?.ok && response?.data?.includes?.(RESPONSE_ERRORS.EMAIL_NOT_FOUND_IN_TOKEN))
        ) {
            throw new Error(RESPONSE_ERRORS.EMAIL_NOT_FOUND_IN_TOKEN);
        }

        const { user, ...termsAndConditions } = response.data.data.user;

        yield put(fetchUserTermsAndConditionSuccess(termsAndConditions));

        if (isInheritUserEnabled && isGetInheritUserKyc) {
            yield put(callTheInheritUserInitialKYCMethod(user?.uuid));
        }

        if (user && Array.isArray(user.Roles)) {
            const roles = user?.Roles;
            cookiesPkg.set(FINX_COOKIE.USER_ROLE, roles.toString());

            const rolesStatus = {
                [USER_ROLES.ADMIN]: false,
                [USER_ROLES.BROKER]: false,
                [USER_ROLES.PARTNER]: false,
                [USER_ROLES.VIP]: false,
                [USER_ROLES.CLUBDEALS]: false,
                [USER_ROLES.UMBRELLA]: false,
            };

            for (let i = 0, len = roles && roles.length; i < len; i++) {
                if (typeof rolesStatus[roles[i]] === "boolean") {
                    rolesStatus[roles[i]] = true;
                }
            }

            rolesStatus[USER_ROLES.CLUBDEALS] && user.StatusClubDeal
                ? cookiesPkg.set(FINX_COOKIE.CLUB_DEAL_STATUS, user.StatusClubDeal)
                : cookiesPkg.remove(FINX_COOKIE.CLUB_DEAL_STATUS);
        } else {
            cookiesPkg.remove(FINX_COOKIE.USER_ROLE);
            cookiesPkg.remove(FINX_COOKIE.CLUB_DEAL_STATUS);
        }

        const { personalData: personalDataKYC } = extractKYCPrefilledData(user);

        const isKYCinitiated = yield select(getUserInitiated);

        if (!isKYCinitiated) {
            yield put(
                updateKYCFormData({
                    personal: personalDataKYC,
                }),
            );
        }

        //TODO: remove this once API refactoring is done
        const { tileData, personalData, identificationData } = yield call(extractUserAdditionalData, user);

        yield put(
            setInvestmentStepData({
                userData: {
                    personalData,
                    identificationData,
                    tileData,
                },
            }),
        );
        yield put(fetchUserDetailsSuccess(user));

        const dashboard = pick(user, [
            "StatusAdequacy",
            "StatusTipster",
            "StatusVIP",
            "StatusEmail",
            "StatusTwoFactor",
            "StatusMobilePhone",
            "KYC",
        ]);

        /**
            Commenting this out because after login we want user to submit Tipster by manually all their se
        */
        /**
            let tipsterId = typeof window === 'object' && require('js-cookie').get("tipsterId")
            if (tipsterId && (dashboard.StatusTipster !== "hide" || dashboard.StatusTipster !== "success")) {
                const res = yield call(setTipsterId, { tipster: tipsterId });
                if (res.data.data) {
                    dashboard.StatusTipster = "hide";
                    yield put(setTipsterData({ ID: tipsterId }));
                }
                require('js-cookie').remove("tipsterId")
            }
        */

        yield put(userDashboardDetailsSuccess(dashboard));

        const { Adequacy } = response.data.data.user.user;
        if (Adequacy && Adequacy.StatusAdequacy !== "uncompleted") {
            const saveAdequacyData = {
                survey_first_step: { TypeEducation: Adequacy.TypeEducation, TypeJob: Adequacy.TypeJob },
                survey_second_step: {
                    bonds: {
                        IsBondsXPConfirmedDate:
                            Adequacy.IsBondsXPConfirmedDate &&
                            yearsOptionsMapping.find((item) => item.value === Adequacy.IsBondsXPConfirmedDate),
                        IsBondsXPConfirmed: Adequacy.IsBondsXPConfirmed,
                    },
                    investment_funds: {
                        IsInvestFundsXPConfirmedDate:
                            Adequacy.IsInvestFundsXPConfirmedDate &&
                            yearsOptionsMapping.find((item) => item.value === Adequacy.IsInvestFundsXPConfirmedDate),
                        IsInvestFundsXPConfirmed: Adequacy.IsInvestFundsXPConfirmed,
                    },
                    shares: {
                        IsSharesXPConfirmedDate:
                            Adequacy.IsSharesXPConfirmedDate &&
                            yearsOptionsMapping.find((item) => item.value === Adequacy.IsSharesXPConfirmedDate),
                        IsSharesXPConfirmed: Adequacy.IsSharesXPConfirmed,
                    },
                    sharing_rights: {
                        IsProfitPartXPConfirmedDate:
                            Adequacy.IsProfitPartXPConfirmedDate &&
                            yearsOptionsMapping.find((item) => item.value === Adequacy.IsProfitPartXPConfirmedDate),
                        IsProfitPartXPConfirmed: Adequacy.IsProfitPartXPConfirmed,
                    },
                    subordinated: {
                        IsSubordLoanXpConfirmedDate:
                            Adequacy.IsSubordLoanXpConfirmedDate &&
                            yearsOptionsMapping.find((item) => item.value === Adequacy.IsSubordLoanXpConfirmedDate),
                        IsSubordLoanXpConfirmed: Adequacy.IsSubordLoanXpConfirmed,
                    },
                },
                survey_third_step: {
                    securities: {
                        BondsXPAssisted:
                            Adequacy.BondsXPAssisted &&
                            investmentServicesYearsOptionsMapping.find((item) => item.value === Adequacy.BondsXPAssisted),
                        BondsXPIndependent:
                            Adequacy.BondsXPIndependent &&
                            investmentServicesYearsOptionsMapping.find((item) => item.value === Adequacy.BondsXPIndependent),
                        BondsXPConsulted:
                            Adequacy.BondsXPConsulted &&
                            investmentServicesYearsOptionsMapping.find((item) => item.value === Adequacy.BondsXPConsulted),
                    },
                    transactions: {
                        BondsTransactions:
                            Adequacy.BondsTransactions &&
                            transactionYearsOptionsMapping.find((item) => item.value === Adequacy.BondsTransactions),
                        BondsAmount:
                            Adequacy.BondsAmount &&
                            transactionSizesOptionsMapping.find((item) => item.value === Adequacy.BondsAmount),
                    },
                },
                submitted_date: Adequacy.DateTime,
            };
            yield put(setAdequacyStart(saveAdequacyData));
        }
        yield put(fetchAdequacySuccess(Adequacy));

        yield put(fetchUserAllDetailsSuccess());
    } catch (err) {
        const { message } = err;
        yield put(fetchUserDetailsFailure(message));
        yield put(fetchAdequacyFailure(message));
        yield put(userDashboardDetailsFailure(message));
        yield put(fetchUserAllDetailsFailure());
    }
}

function* setUserMobileDetailsSaga({ payload: mobileDetails }) {
    try {
        const response = yield call(setUserMobileDetails, mobileDetails);
        const { email } = response.data.data.mobileTile.user;
        if (email) yield put(setUserMobileDetailsSuccess({ ...mobileDetails, step: 2 }));
    } catch (err) {
        yield put(setUserMobileDetailsFailure(err));
    }
}

function* fetchPayInWalletBalanceSaga({ payload }) {
    try {
        const { type, refreshBalance } = payload;
        const response = yield call(checkMangoPayBalance, type, refreshBalance);
        const walletBalance = response && response.data && response.data.data && response.data.data.checkMangoPayBalance;
        yield put(fetchPayInWalletBalanceSuccess(walletBalance));
    } catch (err) {
        yield put(fetchPayInWalletBalanceFailure(err));
    }
}

function* fetchBlockedWalletBalanceSaga({ payload }) {
    try {
        const { type, refreshBalance } = payload;
        const response = yield call(checkMangoPayBalance, type, refreshBalance);
        const walletBalance = response && response.data && response.data.data && response.data.data.checkMangoPayBalance;
        yield put(fetchBlockedWalletBalanceSuccess(walletBalance));
    } catch (err) {
        yield put(fetchBlockedWalletBalanceFailure(err));
    }
}

function* fetchUserPayments({ payload: userID }) {
    try {
        const response = yield call(fetchPaymentsForAUser, userID);
        const payments = response?.data?.data?.user?.user?.LinkPayments || [];
        yield put(fetchUserPaymentsSuccess(payments));
    } catch (err) {
        yield put(fetchUserPaymentsFailure(err));
    }
}

function* fetchUserMinPayInAmountSaga() {
    try {
        const response = yield call(checkMinPayInAmount);
        // const data = response && response.data && response.data.data && response.data.data.checkMangoPayBalance;
        const data = response && response.data && response.data.data && response.data.data.payInAmount;
        yield put(fetchUserMinPayInAmountSuccess(data));
    } catch (err) {
        yield put(fetchUserMinPayInAmountFailure(err));
    }
}

function* updateUserTaxDataSaga({ taxData }) {
    try {
        yield put(
            updateUserTaxDataTransitions({
                isUpdating: true,
            }),
        );

        const response = yield call(setUserTaxData, taxData);

        yield put(updateUserData(response.data.data.setUserTaxData.user));

        yield put(
            updateUserTaxDataTransitions({
                isUpdating: false,
                isSuccessful: true,
            }),
        );
    } catch (err) {
        console.error("updateUserData err: ", err);
        yield put(
            updateUserTaxDataTransitions({
                isUpdating: false,
                isFailed: true,
            }),
        );
    }
}

function* fetchUserSubscriptionsSaga({ userID, callback = () => {} }) {
    try {
        const response = yield call(getUserSubscriptions, userID);
        const data = response?.data?.data?.getUserSubscriptions?.subscriptions;
        callback(data);
        yield put(fetchUserSubscriptionsSuccess(data));
    } catch (err) {
        yield put(fetchUserSubscriptionsFailure(err));
    }
}

function* fetchUserDetailsStart() {
    yield takeLatest(AccountActionTypes.FETCH_PAYMENTS_FOR_USER_START, fetchUserPayments);
    yield takeLatest(AccountActionTypes.SET_USER_SUBSCRIPTION_SUCCESS, fetchUserAllDetailsSaga);
    yield takeLatest(AccountActionTypes.UPDATE_USER_DETAIL_FIELD, fetchUserAllDetailsSaga);
    yield takeLatest(AccountActionTypes.FETCH_USER_DETAILS_START, fetchUserAllDetailsSaga);
    yield takeLatest(AccountActionTypes.USER_DASHBOARD_DETAILS_START, fetchUserAllDetailsSaga);
    yield takeLatest(AccountActionTypes.FETCH_ALL_USER_DETAILS_START, fetchUserAllDetailsSaga);
    yield takeLatest(AccountActionTypes.SET_USER_MOBILE_DETAILS_START, setUserMobileDetailsSaga);
    yield takeLatest(AccountActionTypes.USER_MIN_PAYIN_AMOUNT_START, fetchUserMinPayInAmountSaga);
    yield takeLatest(AccountActionTypes.FETCH_PAY_IN_WALLET_BALANCE_START, fetchPayInWalletBalanceSaga);
    yield takeLatest(AccountActionTypes.FETCH_BLOCKED_WALLET_BALANCE_START, fetchBlockedWalletBalanceSaga);
    yield takeLatest(AccountActionTypes.UPDATE_USER_TAX_DATA_START, updateUserTaxDataSaga);
    yield takeLatest(AccountActionTypes.FETCH_USER_SUBSCRIPTIONS_START, fetchUserSubscriptionsSaga);
}

export function* accountSagas() {
    yield all([call(fetchUserDetailsStart)]);
}
