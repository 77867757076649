import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import cn from "classnames";
import Cookies from "js-cookie";
import { useFormattedMessage, useUtils } from "hooks";
import NavLink from "components/NavLink";
import User from "assets/images/svg/ico-user.svg";
import Dashboard from "assets/images/svg/ico-dashboard.svg";
import House from "assets/images/svg/ico-house.svg";
import TagSolid from "assets/images/svg/tags-solid.svg";
import AdminUser from "assets/images/svg/admin-user.svg";
import BrokerUser from "assets/images/svg/broker-user.svg";
import LogoSvg from "assets/images/svg/FINEXITY_logo_black.svg";
import Umbrella from "assets/images/svg/umbrella.svg";
import { selectUserRoles, selectIsClubDealCompleted, selectUserDetails } from "store/user/account/selectors";
import { selectCurrentTenant, selectIsCurrentTenantFinexity, selectTenant } from "store/app/selectors";
import {
    forceActiveTenantLink,
    forceActiveIssuerLink,
    forceActiveAdminLink,
    forceActiveBrokerLink,
    forceActiveClubDealLink,
    forceActiveUmbrellaLink,
    findRootDomain,
    getTenantAssets,
    forceActivePartnerLink,
} from "utils";
import { ACCOUNT_HASH_VARIABLES, hasCombinedRetailClubDealTenant, TABS } from "constants/index";

const FINX_COOKIE = require("common/constants");

const messages = {
    german: "lp_nav_german",
    english: "lp_nav_english",
    dashboard: "meta_title_dashboard_page",
    marketplace: "marketplace",
    clubdeals: "meta_title_clubdeals_page",
    admin: "nav_admin",
    broker: "meta_title_broker_page",
    partner: "meta_title_partner_page",
    tenant: "nav_tenant",
    issuer: "nav_issuer",
    testing: "nav_testing",
    account: "account_heading",
    umbrella: "nav_umbrella",
    contactTitle: "any_question",
    contactDescription: "support_function",
    contactButton: "contact_us",
};

const isInheritUser = !!Cookies.get(FINX_COOKIE.INHERIT_USER_TOKEN);

const DashBoardNavigation = ({
    menuOpened,
    closeMenu,
    toggleMenu,
    pathname,
    contactModalFlag,
    currentTenant,
    roles,
    isClubDealCompleted,
    isTenantFinexity,
    tenant,
}) => {
    const { formatMessage } = useFormattedMessage();
    const documentHost = useUtils().documentHost;

    const MenuLinkDesktop = ({ href, name, icon, forceActive = false }) => {
        return (
            <li className="header__menu-item">
                <NavLink
                    href={href}
                    forceActive={forceActive}
                    className="menu-item new-link medium"
                    activeClassName="active"
                    onClick={closeMenu}
                >
                    {icon?.()}
                    <span>{name}</span>
                </NavLink>
            </li>
        );
    };

    const MenuLinkMobile = ({ href, iconClass, onClick, name, svg }) => (
        <li className="header__menu-item mobile">
            <a href={href} className="menu-item new-link medium" onClick={onClick}>
                {iconClass ? (
                    <div>
                        <i className={iconClass} />
                    </div>
                ) : (
                    <div>{svg}</div>
                )}
                <span>{name}</span>
            </a>
        </li>
    );

    const tenantLogoLink = getTenantAssets(documentHost, currentTenant).tenantLogo;
    const logoImgAlt = currentTenant?.toUpperCase() || "FINEXITY";

    const dashboardTab = {
        id: TABS.DASHBOARD,
        href: "/dashboard",
        name: formatMessage(messages.dashboard),
        icon: () => <Dashboard />,
    };

    const marketPlaceTab = {
        id: TABS.MARKETPLACE,
        href: "/marketplace",
        forceActive: pathname === "/marketplace/[id]",
        name: formatMessage(messages.marketplace),
        icon: () => <House />,
    };

    const tabs = [
        {
            id: TABS.CLUBDEAL,
            href: isClubDealCompleted ? "/club-deals" : "/club-deals/onboarding",
            forceActive: forceActiveClubDealLink(pathname),
            name: formatMessage(messages.clubdeals),
            icon: () => <TagSolid />,
        },
        {
            id: TABS.BROKER,
            href: "/broker/dashboard",
            forceActive: forceActiveBrokerLink(pathname),
            name: formatMessage(messages.broker),
            icon: () => <BrokerUser />,
        },
        {
            id: TABS.PARTNER,
            href: "/partner/overview",
            forceActive: forceActivePartnerLink(pathname),
            name: formatMessage(messages.partner),
            icon: () => <BrokerUser />,
        },
        {
            id: TABS.ADMIN,
            href: "/admin/products",
            forceActive: forceActiveAdminLink(pathname),
            name: formatMessage(messages.admin),
            icon: () => <AdminUser />,
        },
        {
            id: TABS.TENANT,
            href: "/tenant/overview",
            forceActive: forceActiveTenantLink(pathname),
            name: formatMessage(messages.tenant),
            icon: () => <AdminUser />,
        },

        {
            id: TABS.ISSUER,
            href: "/issuer-manager/overview",
            forceActive: forceActiveIssuerLink(pathname),
            name: formatMessage(messages.issuer),
            icon: () => <BrokerUser />,
        },
        {
            id: TABS.UMBRELLA,
            href: "/umbrella/users",
            forceActive: forceActiveUmbrellaLink(pathname),
            name: formatMessage(messages.umbrella),
            icon: () => <Umbrella />,
        },
    ];

    //ADDITIONAL TABS FOR MOBILE VIEW
    const desktopTabs = tabs.filter?.(
        (item) =>
            !!roles?.find?.((role) => {
                // Need to add this check to check for fully on-boarded Issuer user
                /*
            if(role === USER_ROLES.ISSUER) {
                return (
                    userDetails?.Issuer?.StatusIssuerAccount === "active" ||
                    userDetails?.Issuer?.StatusIssuerAccount == true
                );
            }
        */

                return hasCombinedRetailClubDealTenant(tenant?.TechnicalName) && item.id == TABS.CLUBDEAL
                    ? false
                    : role == item.id;
            }),
    );

    return (
        <>
            <NavLink
                isForcePushed
                href={tenant?.RootDomainURLEnabled === "yes" && tenant?.Domain ? findRootDomain(tenant?.Domain) : "/"}
                className={`logo ${isTenantFinexity ? "mr-0 d-flex align-center" : ""}`}
                onClick={closeMenu}
            >
                {isTenantFinexity ? <LogoSvg width={108} height={16} /> : <img src={tenantLogoLink} alt={logoImgAlt} />}
            </NavLink>
            <nav
                id="headerMenuWrapper"
                className={cn({
                    "header__menu-wrapper flex-row": true,
                    open: menuOpened,
                })}
            >
                <ul className="header__menu flex-row">
                    <MenuLinkDesktop key={Math.random()} {...dashboardTab} />
                    <MenuLinkDesktop key={Math.random()} {...marketPlaceTab} />
                    {desktopTabs?.map?.((tabProps) => {
                        return <MenuLinkDesktop key={Math.random()} {...tabProps} />;
                    })}
                    <MenuLinkMobile
                        name={formatMessage(messages.account)}
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.hash = ACCOUNT_HASH_VARIABLES.ACCOUNT_MAIN;
                            // setAccountModalOpen(true)
                        }}
                        svg={<User />}
                    />
                </ul>
                {isTenantFinexity && (
                    <div className="property-interest-container mobile mt-12 mb-27 px-5">
                        <div className="property-interest-block text-center shadow border-radius">
                            <img className="action-image mr-2" src={"/images/interestTelephone.png"} alt="" />
                            <img className="action-image mr-2" src={"/images/interestMail.png"} alt="" />
                            <img className="action-image" src={"/images/appointment.png"} alt="" />
                            <h4 className="mt-0">{formatMessage(messages.contactTitle)}</h4>
                            <p className="body-medium">{formatMessage(messages.contactDescription)}</p>
                            <button className="mb-0" onClick={() => contactModalFlag(true)}>
                                {formatMessage(messages.contactButton)}
                            </button>
                        </div>
                    </div>
                )}
                <div className="desktop">
                    <ul className="header__right-menu flex-row">
                        <li className={`header__menu-item user ${isInheritUser ? "inherit-user" : ""}`}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.hash = ACCOUNT_HASH_VARIABLES.ACCOUNT_MAIN;
                                    // setAccountModalOpen(true)
                                }}
                            >
                                <User />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className="header__right-menu mobile">
                <div className="flex">
                    <div className="hamburger" onClick={toggleMenu} onKeyPress={toggleMenu}>
                        <span className={cn({ "nav-icon pointer mobile": true, open: menuOpened })} role="button" tabIndex={0}>
                            <span />
                            <span />
                            <span />
                            <span />
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    roles: selectUserRoles,
    currentTenant: selectTenant,
    isClubDealCompleted: selectIsClubDealCompleted,
    isTenantFinexity: selectIsCurrentTenantFinexity,
    userDetails: selectUserDetails,
    tenant: selectCurrentTenant,
});

export default connect(mapStateToProps, null)(DashBoardNavigation);
