export const placedVolumeOptions = {
    first: "zero_one_million_euros",
    second: "one_three_million_euros",
    third: "three_five_million_euros",
    fourth: "five_ten_million_euros",
    fifth: "ten_fifty_million_euros",
    sixth: "fifty_hundred_million_euros",
    seventh: "less_than_hundred_million",
};

export const titleOptions = {
    first: "doctor",
    second: "dr_med",
    third: "professor",
    fourth: "prof_doctor",
};

export const licenseOptions = {
    first: "license_options_first",
    second: "license_options_second",
    third: "no_license",
};

export const genderOptions = {
    first: "miss",
    second: "mister",
    third: "diverse",
};
